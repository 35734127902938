<template>
    <div class="result">
        <div class="result-main">
            <div class="result-title">
                <h2>2024CUA第三届中国泌尿男科医工创新大赛</h2>
                <i>{{ title }}</i>
                <h3>晋级选手</h3>
            </div>
            <div class="result-head">
                <div class="result-carry" v-for="(item,index) in list">
                    <div class="carry-head">
                        <img :src="item.player_img || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'"/>
                    </div>
                    <div class="carry-name">{{ item.player_name }}<p>{{ item.player_hospital_name }}</p></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '',
                list: []
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
				console.log(this.apiUrl.byresult)
                this.$axios.post(this.apiUrl.cxdsresult,{group_id:this.$route.query.group_id}).then(res => {
					if(res.msg.indexOf("初创成长")!=-1){
						this.list = res.data.array_data.slice(0,6)
					}else{
						this.list = res.data.array_data.slice(0,3)
					}
                    // this.list = res.data.array_data
					
                    this.title = res.msg
                })
            },
        }
    }
</script>
<style scoped lang="scss">
	
    .result{
        background: url("~@/assets/img/cxds/cxdsBj2024.jpg") no-repeat;
        background-size: 100% 100%;
        height: 100vh;
        .result-main{
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            .result-title{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-weight: bold;
                font-family:SimHei;
				color: #fff;
				margin-top: 60px;
				h2{font-size: 47px;line-height: 80px;}
				i{font-size: 33px; font-style:normal; font-weight:100;line-height: 55px;}
				h3{
					font-family:'楷体';
					font-size: 50px;
					background: #44B0F6;
					width: 258px;
					line-height: 57px;
					border-radius: 57px;
				}
            }
            .result-head{
                margin-top: 60px;
                .result-carry{
                    vertical-align: top;
                    margin: 0 4%;
                }
            }
			.result-carry{
				width: 360px;
			    display: inline-block;
			    box-sizing: border-box;
			    position: relative;
			    .carry-ranking{
			        position: absolute;
			        right: 0px;
			        top: 0px;
			        width: 50px;
			        height: 50px;
			    }
				.carry-head{
				    width: 220px;
				    height: 220px;
					border-radius: 50%;
				    padding: 3px;
				    margin: 0 auto;
				    img{
				        width: 100%;
				        height: 100%;
				        border-radius: 100%;
				    }
				}
				.carry-name{
				    text-align: center;
				    font-size: 33px;
				    margin-bottom: 10px;
					color: #fff;
				    margin-top: 10px;
					line-height: 43px;
					p{font-size: 25px;}
				}
			}
			
    //         .result-carry{
    //             display: inline-block;
    //             box-sizing: border-box;
    //             position: relative;
				// width: 200px;
    //             .carry-ranking{
    //                 position: absolute;
    //                 right: 0px;
    //                 top: 0px;
    //                 width: 50px;
    //                 height: 50px;
    //             }
				// .carry-head{
				//     width: 180px;
				//     height: 180px;
				// 	border-radius: 50%;
				//     padding: 3px;
				//     margin: 0 auto;
				//     img{
				//         width: 100%;
				//         height: 100%;
				//         border-radius: 100%;
				//     }
				// }
				// .carry-name{
				//     text-align: center;
				//     font-size: 30px;
				//     margin-bottom: 10px;
				// 	color: #fff;
				//     margin-top: 10px;
				// 	line-height: 35px;
				// 	p{font-size: 22px;}
				// }
    //         }
        }
    }
</style>

